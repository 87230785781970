/** Change pagination colors */

ul.pager {
	a {
		background-color: #2c3e50;
	}

	.disabled span {
		background-color: #9a9a9a;
	}
}
